<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div style="">
        <div class="text-center">
            <v-dialog
                    v-model="dialog"
                    persistent
                    width="50"
            >
                <img src='../../assets/loader_spinner.gif' style="width: 50px; ">

            </v-dialog>
        </div>
        <v-row>
            <v-col>
                <v-container class="my-5 adminContainer">
                    <v-row>
                        <v-col cols="0" md="2">
                            <admin-stat-menu></admin-stat-menu>
                        </v-col>
                        <v-col cols="12" md="10">
                            <v-row>
                                <v-col class="overflow-x-auto">
                                    <table class="table-left-s table-left-s-b" style="width: 960px;">
                                        <colgroup>
                                            <col style="width:15%"/>
                                            <col style="width:85%"/>
                                        </colgroup>
                                        <tr>
                                            <th class="th">검색방법</th>
                                            <td class="td" style="overflow-wrap: break-word; display: revert;">
                                                <template v-if="!isMobile">
                                                    <input type="radio" id="D" value="D" v-model="isSearch"
                                                           style="width: 20px; height: 12px;"
                                                           @change="changeSearch($event)">
                                                    <label for="D">기간</label>
                                                    <input type="radio" id="M" value="M" v-model="isSearch"
                                                           style="width: 20px; height: 12px;"
                                                           @change="changeSearch($event)">
                                                    <label for="M">월별</label>
                                                    <input type="radio" id="Y" value="Y" v-model="isSearch"
                                                           style="width: 20px; height: 12px;"
                                                           @change="changeSearch($event)">
                                                    <label for="Y">년도별</label>
                                                </template>
                                                <template v-else>
                                                    <div>
                                                        <input type="radio" id="D" value="D" v-model="isSearch"
                                                               style="width: 20px; height: 12px;"
                                                               @change="changeSearch($event)">
                                                        <label for="D">기간</label>
                                                    </div>

                                                    <div>
                                                        <input type="radio" id="M" value="M" v-model="isSearch"
                                                               style="width: 20px; height: 12px;"
                                                               @change="changeSearch($event)">
                                                        <label for="M">월별</label>
                                                    </div>
                                                    <div>
                                                        <input type="radio" id="Y" value="Y" v-model="isSearch"
                                                               style="width: 20px; height: 12px;"
                                                               @change="changeSearch($event)">
                                                        <label for="Y">년도별</label>
                                                    </div>

                                                </template>
                                            </td>
                                        </tr>
                                        <template v-if="isSearch == 'M'">
                                            <tr>
                                                <th>
                                                    검색기간
                                                </th>
                                                <td>
                                                    <select v-model="visitYear" @change="changeYear($event)"
                                                            style="background-color: #fff;">
                                                        <option v-for="(n, i) in yearList" :value="n">{{n}}년</option>
                                                    </select>
                                                </td>
                                            </tr>
                                        </template>
                                        <template v-if="isSearch === 'D'">
                                            <tr>
                                                <th rowspan="2">검색기간</th>
                                                <td>
                                                    <v-btn
                                                            color="primary"
                                                            class="mx-1 white--text center-block tmp-btn"
                                                            x-small
                                                            @click="setSearchBtn1(1)"
                                                    >
                                                        1개월
                                                    </v-btn>
                                                    <v-btn
                                                            color="primary"
                                                            class="mx-1 white--text center-block tmp-btn"
                                                            x-small
                                                            @click="setSearchBtn1(2)"
                                                    >
                                                        2개월
                                                    </v-btn>
                                                    <v-btn
                                                            color="primary"
                                                            class="mx-1 white--text center-block tmp-btn"
                                                            x-small
                                                            @click="setSearchBtn1(3)"
                                                    >
                                                        3개월
                                                    </v-btn>
                                                    <v-btn
                                                            color="primary"
                                                            class="mx-1 white--text center-block tmp-btn"
                                                            x-small
                                                            @click="setSearchBtn1(6)"
                                                    >
                                                        6개월
                                                    </v-btn>
                                                    <v-btn
                                                            color="primary"
                                                            class="mx-1 white--text center-block tmp-btn"
                                                            x-small
                                                            @click="setSearchBtn1(12)"
                                                    >
                                                        12개월
                                                    </v-btn>
                                                    <v-btn
                                                            color="primary"
                                                            class="mx-1 white--text center-block tmp-btn"
                                                            x-small
                                                            @click="setSearchBtn1(0)"
                                                    >
                                                        초기화
                                                    </v-btn>
                                                </td>
                                            </tr>
                                            <tr>
                                                <!--<th class="th">검색기간</th>-->
                                                <td class="td" style="display: block;">
                                                    <select v-model="searchDate.startDateY" style="width: 80px; float:left;"
                                                            @change="selectChange($event)">
                                                        <option v-for="(n, i) in yearList" :value="n">{{n}}</option>
                                                    </select>
                                                    <span class="mx-1 float-left">년</span>
                                                    <select v-model="searchDate.startDateM" style="width: 65px; float:left;"
                                                            @change="selectChange($event)">
                                                        <option v-for="(n, i) in 12" :value="n">{{n}}</option>
                                                    </select>
                                                    <span class="mx-1 float-left">월</span>
                                                    <select v-model="searchDate.startDateD" style="width: 65px; float:left;"
                                                            @change="selectChange($event)">
                                                        <option v-for="(n, i) in 31" :value="n">{{n}}</option>
                                                    </select>
                                                    <span class="mx-1 float-left">일~</span>

                                                    <select v-model="searchDate.endDateY" style="width: 80px; float:left;"
                                                            @change="selectChange($event)">
                                                        <option v-for="(n, i) in yearList" :value="n">{{n}}</option>
                                                    </select>
                                                    <span class="mx-1 float-left">년</span>
                                                    <select v-model="searchDate.endDateM" style="width: 65px; float:left;"
                                                            @change="selectChange($event)">
                                                        <option v-for="(n, i) in 12" :value="n">{{n}}</option>
                                                    </select>
                                                    <span class="mx-1 float-left">월</span>
                                                    <select v-model="searchDate.endDateD" style="width: 65px; float:left;"
                                                            @change="selectChange($event)">
                                                        <option v-for="(n, i) in 31" :value="n">{{n}}</option>
                                                    </select>
                                                    <span class="mx-1 float-left">일</span>
                                                    <v-btn
                                                            color="primary"
                                                            class="mx-3 white--text center-block tmp-btn"
                                                            x-small
                                                            @click="searchClick1"
                                                    >
                                                        검색
                                                    </v-btn>
                                                </td>
                                            </tr>
                                        </template>
                                    </table>
                                </v-col>

                                <v-col cols="12" class="sub-title-3 mt-2">
                                    방문횟수
                                </v-col>
                                <!--<v-col class="hidden-md-and-down">-->
                                <v-col class="overflow-x-auto">
                                    <table class="adminListTable table-right">
                                        <tr>
                                            <th>{{isSearchD}}</th>
                                            <th>캠A</th>
                                            <th>캠B</th>
                                            <th>캠C</th>
                                            <th>캠D</th>
                                            <th>장미</th>
                                            <th>별님</th>
                                            <th>달님</th>
                                            <th>하늘</th>
                                            <th>구름</th>
                                            <th>백합</th>
                                            <th>동백</th>
                                            <th>목련</th>
                                            <th class="text--blue">합계</th>
                                            <th>점유율</th>
                                        </tr>
                                        <tr v-for="(item, i) in statList" class="">
                                            <th>{{item.statId}}</th>
                                            <td>{{item.count_A | de0}}</td>
                                            <td>{{item.count_B | de0}}</td>
                                            <td>{{item.count_C | de0}}</td>
                                            <td>{{item.count_D | de0}}</td>
                                            <!--<td>{{item.count_C}}</td>-->
                                            <td>{{item.count_01 | de0}}</td>
                                            <td>{{item.count_02 | de0}}</td>
                                            <td>{{item.count_03 | de0}}</td>
                                            <td>{{item.count_04 | de0}}</td>
                                            <td>{{item.count_05 | de0}}</td>
                                            <td>{{item.count_06 | de0}}</td>
                                            <td>{{item.count_07 | de0}}</td>
                                            <td>{{item.count_08 | de0}}</td>
                                            <td class="text--blue">{{item.count_tot | de0}}</td>
                                            <td>{{item.count_per}}%</td>
                                        </tr>
                                        <tr>
                                            <th class="text--blue">합계</th>
                                            <td class="text--blue">{{totInfo.count_A | de0}}</td>
                                            <td class="text--blue">{{totInfo.count_B | de0}}</td>
                                            <td class="text--blue">{{totInfo.count_C | de0}}</td>
                                            <td class="text--blue">{{totInfo.count_D | de0}}</td>
                                            <!--<td>{{item.count_C}}</td>-->
                                            <td class="text--blue">{{totInfo.count_01 | de0}}</td>
                                            <td class="text--blue">{{totInfo.count_02 | de0}}</td>
                                            <td class="text--blue">{{totInfo.count_03 | de0}}</td>
                                            <td class="text--blue">{{totInfo.count_04 | de0}}</td>
                                            <td class="text--blue">{{totInfo.count_05 | de0}}</td>
                                            <td class="text--blue" >{{totInfo.count_06 | de0}}</td>
                                            <td class="text--blue">{{totInfo.count_07 | de0}}</td>
                                            <td class="text--blue">{{totInfo.count_08 | de0}}</td>
                                            <td class="text--blue">{{totInfo.count_tot | de0}}</td>
                                            <td>100.0%</td>
                                        </tr>
                                        <tr>
                                            <th>점유율</th>
                                            <td>{{this.setPercent(totInfo.count_A, totInfo.count_tot)}}%</td>
                                            <td>{{this.setPercent(totInfo.count_B, totInfo.count_tot)}}%</td>
                                            <td>{{this.setPercent(totInfo.count_C, totInfo.count_tot)}}%</td>
                                            <td>{{this.setPercent(totInfo.count_D, totInfo.count_tot)}}%</td>
                                            <!--<td>{{this.setPercent(totInfo.count_08, totInfo.count_tot)}}%</td>-->
                                            <td>{{this.setPercent(totInfo.count_01, totInfo.count_tot)}}%</td>
                                            <td>{{this.setPercent(totInfo.count_02, totInfo.count_tot)}}%</td>
                                            <td>{{this.setPercent(totInfo.count_03, totInfo.count_tot)}}%</td>
                                            <td>{{this.setPercent(totInfo.count_04, totInfo.count_tot)}}%</td>
                                            <td>{{this.setPercent(totInfo.count_05, totInfo.count_tot)}}%</td>
                                            <td>{{this.setPercent(totInfo.count_06, totInfo.count_tot)}}%</td>
                                            <td>{{this.setPercent(totInfo.count_07, totInfo.count_tot)}}%</td>
                                            <td>{{this.setPercent(totInfo.count_08, totInfo.count_tot)}}%</td>
                                            <td>{{this.setPercent(totInfo.count_tot,totInfo.count_tot)}}%</td>
                                            <td></td>
                                        </tr>
                                    </table>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    ※입금완료된 내역만 포함됩니다.<br>
                                    ※방문횟수는 예약(입실)일 기준으로 산출됩니다.
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import AdminStatMenu from '../../components/AdminStatMenu'

    export default {
        name: 'AdminStatDescCount',
        components: {
            AdminStatMenu
        },
        data: () => ({
            searchDate: {
                startDate: "",
                endDate: "",
                startDateY: "",
                startDateM: "",
                startDateD: "",
                endDateY: "",
                endDateM: "",
                endDateD: "",
            },
            thisYear: "",
            today: "",
            visitYear: "",
            yearList: [],
            statList: [],
            sumInfo: [],
            totInfo: {},
            isSearch: "D",
            isSearchD: "월",
            dialog: false,
            isMobile: false,

        }),
        created: function () {
            // 모바일 체크
            this.isMobile = this.$isMobile();

            // 맨위로
            window.scrollTo(0, 0);
            //this.$isAdminCheck(this.$store.state.admin.isLogin);
            //this.$isAuthCheck(this.$getCookie("AUTH"));

            let now = new Date();	// 현재 날짜 및 시간
            let year = now.getFullYear();	// 연도
            let month = now.getMonth() + 1;  // 월
            let date = now.getDate();  // 날짜
            this.visitYear = String(year);

            this.searchDate.endDateY = year;
            this.searchDate.endDateM = month;
            this.searchDate.endDateD = date;

            this.searchDate.startDateY = year;
            this.searchDate.startDateM = "1";
            this.searchDate.startDateD = "1";

            this.thisYear = year;
            this.today = year + "-" + month + "-" + date;

            for (year; year > 2020; year--) {

                this.yearList.push(year);
            }

            this.getList();

        },
        methods: {
            getList(val) {

                this.dialog = true;

                let formdata = {};

                if (val !== undefined) {

                    formdata = val;
                } else {
                    formdata = {}
                }

                switch (this.isSearch) {
                    case "Y": {
                        formdata['isSearch'] = this.isSearch;
                        this.isSearchD = "년";
                        break;
                    }
                    case "M": {
                        formdata['isSearch'] = this.isSearch;
                        formdata['visitYear'] = this.visitYear;
                        this.isSearchD = "월";
                        break;
                    }
                    case "D": {
                        formdata['isSearch'] = this.isSearch;
                        if(formdata.visitStartDate == undefined){

                            this.searchClick1()
                            return;
                        }

                        this.isSearchD = "";
                        break;
                    }

                }

                return this.$store.dispatch("admin/getStatResvitDesc", formdata)
                    .then((resp) => {
                        setTimeout(() => {

                            this.statList = resp.message;
                            this.totInfo = resp.sumInfo;
                            if (this.statList != undefined) {
                                for (let i = 1; Object.keys(this.statList).length + 1 > i; i++) {

                                    if(this.totInfo.count_tot == 0){
                                        this.statList[i].count_per = 0;
                                    } else{
                                        this.statList[i].count_per = ((this.statList[i].count_tot / this.totInfo.count_tot) * 100).toFixed(1);
                                    }

                                }
                            } else {

                            }
                            this.dialog = false;

                        }, 300)
                    })
                    .catch((err) => {
                        alert(err);
                        this.dialog = false;
                    })
            },
            setSearchBtn(val) {

                if (val === 0) {

                    this.searchDate.endDate = "";
                    this.searchDate.startDate = "";

                } else {
                    let date = new Date();
                    let year = date.getFullYear();
                    let month = ("0" + (1 + date.getMonth())).slice(-2);
                    let day = ("0" + date.getDate()).slice(-2);

                    this.searchDate.endDate = year + "-" + month + "-" + day;

                    // val(달) 전
                    let monthAgo = new Date(date.setMonth(date.getMonth() - val));

                    let year1 = monthAgo.getFullYear();
                    let month1 = ("0" + (1 + monthAgo.getMonth())).slice(-2);
                    let day1 = ("0" + monthAgo.getDate()).slice(-2);

                    this.searchDate.startDate = year1 + "-" + month1 + "-" + day1;
                }

            },
            setSearchBtn1(val) {

                let date = new Date();
                let year = date.getFullYear();
                let month = 1 + date.getMonth();
                let month0 = ("0" + (1 + date.getMonth())).slice(-2);
                let day = date.getDate();
                let day0 = ("0" + date.getDate()).slice(-2);

                this.searchDate.endDate = year + "-" + month0 + "-" + day0;
                this.searchDate.endDateY = year;
                this.searchDate.endDateM = month;
                this.searchDate.endDateD = day;

                if (val === 0) {
                    this.searchDate.startDate = "2021-01-01";
                    this.searchDate.startDateY = "2021";
                    this.searchDate.startDateM = "1";
                    this.searchDate.startDateD = "1";

                } else {
                    // val(달) 전
                    let monthAgo = new Date(date.setMonth(date.getMonth() - val));

                    let year1 = monthAgo.getFullYear();
                    let month1 = ("0" + (1 + monthAgo.getMonth())).slice(-2);
                    let day1 = ("0" + monthAgo.getDate()).slice(-2);

                    this.searchDate.startDate = year1 + "-" + month1 + "-" + day1;
                    this.searchDate.startDateY = year1;
                    this.searchDate.startDateM = (1 + monthAgo.getMonth());
                    this.searchDate.startDateD = monthAgo.getDate();
                }

            },
            searchClick() {
                let formdata = {};

                let startDate = $('#startDate').val();
                let endDate = $('#endDate').val();

                if (startDate.length > 0) {
                    formdata['visitStartDate'] = startDate;
                    this.searchDate.startDate = startDate;

                }

                if (endDate.length > 0) {
                    formdata['visitEndDate'] = endDate;
                    this.searchDate.endDate = endDate;
                }

                this.getList(formdata);

            },
            searchClick1(){ // 기존 사이트 검색 방식

                let formdata = {};
                let startDate = "";
                let startDateY = "";
                let startDateM = "";
                let startDateD = "";
                let endDate = "";
                let endDateY = "";
                let endDateM = "";
                let endDateD = "";

                // 검색 시작 날짜
                if(this.searchDate.startDateY > 0  && this.searchDate.startDateY !== undefined){
                    startDateY = this.searchDate.startDateY+"";
                }else {
                    startDateY = '2021';
                }
                if(this.searchDate.startDateM > 0  && this.searchDate.startDateM !== undefined){
                    startDateM = ("0" + this.searchDate.startDateM).slice(-2);
                }else {
                    startDateM = '01';
                }
                if(this.searchDate.startDateD > 0  && this.searchDate.startDateD !== undefined){
                    startDateD = ("0" + this.searchDate.startDateD).slice(-2);
                }else {
                    startDateD = '01';
                }

                // 검색 종료 날짜
                if(this.searchDate.endDateY > 0  && this.searchDate.endDateY !== undefined){
                    endDateY = this.searchDate.endDateY+"";
                }else {
                    //endDateY = null;
                }
                if(this.searchDate.endDateM > 0  && this.searchDate.endDateM !== undefined){
                    endDateM = ("0" + this.searchDate.endDateM).slice(-2);
                }else {
                    //endDateM = null;
                }
                if(this.searchDate.endDateD > 0  && this.searchDate.endDateD !== undefined){
                    endDateD = ("0" + this.searchDate.endDateD).slice(-2);
                }else {
                    //endDateD = null;
                }

                startDate = startDateY + "-" + startDateM + "-" + startDateD;
                if(endDateY.length > 0 && endDateM.length > 0 && endDateD.length > 0){
                    endDate = endDateY + "-" + endDateM + "-" + endDateD;
                }

                if (startDate.length > 0) {
                    formdata['visitStartDate'] = startDate;
                    this.searchDate.startDate = startDate;
                }

                if (endDate.length > 0) {
                    formdata['visitEndDate'] = endDate;
                    this.searchDate.endDate = endDate;
                }

                this.getList(formdata);
            },
            selectChange(event, item) {

                //item.saleStartDate = this.numberPad(item.startDateM, 2) + "-" + this.numberPad(item.startDateD, 2);
                //item.saleEndDate = this.numberPad(item.endDateM, 2) + "-" + this.numberPad(item.endDateD, 2);

            },
            setPercent(val, val2) {

                if (val == undefined) val = 0;
                if (val2 == undefined || val2 == "0") val2 = 100;
                return (val / val2 * 100).toFixed(1);

            },
            changeYear(event) {
                this.visitYear = event.target.value;
                this.getList();

            },
            changeSearch(event) {
                this.getList();
                this.setDatePicker();
            },
            setDatePicker() {
                this.$nextTick(function () {
                    let _this = this;
                    //$('#startDate').datepicker();

                    $('#startDate').daterangepicker({
                        "locale": {
                            "format": "YYYY-MM-DD",
                            "separator": " ~ ",
                            "applyLabel": "확인",
                            "cancelLabel": "취소",
                            "daysOfWeek": ["일", "월", "화", "수", "목", "금", "토"],
                            "monthNames": ["1월", "2월", "3월", "4월", "5월", "6월", "7월", "8월", "9월", "10월", "11월", "12월"],
                        }, singleDatePicker: true,
                    });

                    $('#endDate').daterangepicker({
                        "locale": {
                            "format": "YYYY-MM-DD",
                            "separator": " ~ ",
                            "applyLabel": "확인",
                            "cancelLabel": "취소",
                            "daysOfWeek": ["일", "월", "화", "수", "목", "금", "토"],
                            "monthNames": ["1월", "2월", "3월", "4월", "5월", "6월", "7월", "8월", "9월", "10월", "11월", "12월"],
                        }, singleDatePicker: true,
                    });

                    $('#startDate').val('');
                    $('#endDate').val('');

                })
            }
        },
        mounted() {
            this.$nextTick(() => {

            })
        },
    }
</script>

<style>

</style>

